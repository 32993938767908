import React from "react";
import BackToTop from "../components/BackToTop";
import Contact from "../components/Contact";
import Footer from "../Layout/Footer";
import FooterAlt from "../Layout/FooterAlt";
import Process from "../components/Process";
import Services from "../components/Services";
import Portfolio from "../components/Portfolio";

/**
 * Original
 * <Services />
      <About />
      <Process />
      <Counter />
      <Testimonial />
      <Team />
      <Portfolio />
      <Pricing />
      <Blog />
      <CTA />
      <Contact />
      <Footer />
      <FooterAlt />
      <BackToTop />
 * @param {any} props 
 * @returns 
 */
const Layout = (props) => {
  return (
    <React.Fragment>
      {props.children}
      <Services />
      <Process />
      <Portfolio />
      <Contact />
      <Footer />
      <FooterAlt />
      <BackToTop />
    </React.Fragment>
  );
};

export default Layout;
