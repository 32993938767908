import React from "react";
import { Col, Container, Row } from "reactstrap";

const ProcessCard = (props) => {
  return (
    <React.Fragment>
      <Col lg={3} md={6}>
        <div className= "process-box text-center">
          <div className="process-count text-white mt-4">
            <h3 className="process-number">{props.number}</h3>
          </div>

          <div className="process-content bg-light mt-5 rounded p-4">
            <h5 className="fs-18">{props.step}</h5>
            <p className="text-muted mb-0">{props.caption}</p>
          </div>
        </div>
        {/* <!--end process-box--> */}
      </Col>
    </React.Fragment>
  );
}

// Process Data

const ProcessData = [
  {
    number: "01",
    step: "Define problem",
    caption: "Each sprint starts with defining the the problems and goals we are trying to achieve",
  },
  {
    number: "02",
    step: "Plan the sprint",
    caption: "Ensure proper planning based on our goals. When do we have the alignment meetings and when do we demo.",
  },
  {
    number: "03",
    step: "Design & develop",
    caption: "Design and develop based on the user stories and tasks and ensure any alignment with the business during this time.",
  },
  {
    number: "04",
    step: "Demo, review & repeat",
    caption: "Focus for us is to show and demo as early as possible to show results and always adjust accordingly.",
  },
];

const Process = () => {
  return (
    <React.Fragment>
      <section className="bg-process section" id="process">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="header-title text-center">
                <h3>Our Process</h3>
                <div className="title-border mt-3"></div>
                <p className="title-desc text-muted mt-3">
                  We know that process depends on context and the company. We run everything 
                  agile with sprints but where the focus is on showing results, demo and fail early.
                  We also understand that involving the business and customers early on is important
                  to provide most value.
                </p>
                <p className="title-desc text-muted mt-3">
                   Depending on the type of project, we'll give an overall estimate. But we'll run the development in sprints to ensure early feedback and alingment. 
                </p>
              </div>
            </Col>
          </Row>

          <Row className="mt-5 pt-2">
            {/* Process Component Start */}

            {ProcessData.map((value, idx) => (
              <ProcessCard
                key={idx}
                number={value.number}
                step={value.step}
                caption={value.caption}
                isShow={value.isShow}
              />
            ))}

            {/* Process Component End */}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default Process;
