import { axiosInstance } from './axios';

class FeedbackService {
        /**
     * sendFeedback
     * @param {any} userData 
     * @param {string} comment 
     * @param {string} email
     * @param {any} feedbackType 
     * @return {any}
     */
    async sendFeedback(comment, email, name) {
        try {
      
            const data = {
                userData: { name },
                comment,
                email,
                feedbackType: 'IDEA',
            };
              
            const response = await axiosInstance.post('feedback', data, {
                withCredentials: false
            });
            const returnData = response.data;
        
            console.log('sendFeedback', returnData);
            if (returnData) {
                return response.data.data;
            }
            
            throw Error();
        } catch (err) {
            throw new Error('Error');
        }
    }
      
}

export default new FeedbackService();
  