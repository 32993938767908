import React from "react";
import { Col, Container, Row } from "reactstrap";

import img01 from "../assets/images/feature/img-01.png";
import img02 from "../assets/images/feature/img-02.png";
import img03 from "../assets/images/feature/img-03.png";
import img04 from "../assets/images/feature/img-04.png";
import img05 from "../assets/images/feature/img-05.png";
import img06 from "../assets/images/feature/img-06.png";

const ServicesCard = (props) => {
  return (
    <>
      <Col lg={4} md={6}>
        <div
          className={
            props.isShadow
              ? "service-box text-center mt-4 box-shadow"
              : "service-box text-center mt-4"
          }
        >
          <img src={props.imgsrc} alt="" className="img-fluid" />
          <h5 className="fs-18 mt-4">{props.title}</h5>
          <div className="lighlight-border mt-3"></div>
          <p className="text-muted mt-3">{props.caption}</p>
        </div>
      </Col>
      {/* <!--end col--> */}
    </>
  );
}

// Services data

const ServicesData = [
  {
    imgsrc: img01,
    title: "CMS Development",
    caption:
      "We can assist with support and development in Umbraco or Optimizely. Do you need a specific module or component, or do you need to simply integrate with other tools then we can assist",
  },
  {
    imgsrc: img04,
    title: "MarTech Integrations",
    caption:
      "Any kind of integration between any platform in the MarTech Stack such as PIM, CMS, DAM, Social Media or Marketing Automation. We can even offer to deploy and support the solution afterwards.",
    isShadow: true,
  },
  {
    imgsrc: img02,
    title: "E2E Product Development",
    caption:
      "Do you have specific needs for a solution? We can lead product development from concept to actual prototype and product. We always focus on the end-user and have extensive knowledge in languages such as Node.js and C# + React and Angular X",
  },
];

const Services = () => {
  return (
    <>
      <section className="section" id="services">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}> 
              <div className="header-title text-center">
                <p className="text-uppercase text-muted mb-2">
                  Providing the following services
                </p>
                <h3>We create great digital solutions</h3>
                <div className="title-border mt-3"></div>
                <p className="text-muted mt-3">
                  We craft digital solutions with full focus on product, end-users and technology. We do so to create the best digital experiences for users.
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            {/* Services Component Start */}

            {ServicesData.map((value, idx) => (
              <ServicesCard
                key={idx}
                imgsrc={value.imgsrc}
                title={value.title}
                caption={value.caption}
                isShadow={value.isShadow}
              />
            ))}

            {/* Services Component End */}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Services;
