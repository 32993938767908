import axios from 'axios';

// axios.defaults.headers.post['content-type'] = 'application/json';

export const axiosInstance = axios.create({
  baseURL: 'https://europe-west1-aktietrenden.cloudfunctions.net/socialMediaTooling/api/v1/',
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json',
  },
});

/* eslint-disable no-param-reassign */
axiosInstance.interceptors.request.use(async axiosConfig => {
  const key = '468e2a1738c54fd99475b27e7316b8d3';

  axiosConfig.params = { ...axiosConfig.params, key };

  return axiosConfig;
});

