import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

//
import gridForHootLayout from "../assets/images/portfolio/2.Grid.png";
import gridForHootCompose from "../assets/images/portfolio/4.Composer.png";
import shutterstockForHoot from "../assets/images/portfolio/1.ShutterSearchFind.png";
import beautifulStockPhotos from "../assets/images/portfolio/1.UnsplashSearchFind.png";

const GalleryData = [
  {
    image: gridForHootLayout,
    title: "Grid for Hoot - Grid View",
    category: ["Social Media", "Hootsuite"],
    link: "https://instramgram-grid-for-hootsuite.web.app/"
  },
  {
    image: gridForHootCompose,
    title: "Grid for Hoot - Create post",
    category: ["Social Media", "Hootsuite"],
    link: "https://instramgram-grid-for-hootsuite.web.app/"
  },
  {
    image: shutterstockForHoot,
    title: "Shutterstock for Hoot - Royalty free photos",
    category: ["Social Media", "Hootsuite", "Shutterstock"],
    link: "https://instramgram-grid-for-hootsuite.web.app/"
  },
  {
    image: beautifulStockPhotos,
    title: "Beautiful Stock Photos (Unsplash)",
    category: ["Social Media", "Hootsuite", "Unsplash"],
    link: "https://instramgram-grid-for-hootsuite.web.app/"
  },
];

const Gallery = () => {
  const [displayCategory, setCategory] = useState("All");
  const [photoIndex, setphotoIndex] = useState(0);
  const [isOpen, setisOpen] = useState(false);
  const images = [gridForHootLayout, gridForHootCompose, shutterstockForHoot, beautifulStockPhotos];

  return (
    <React.Fragment>
      <section className="section" id="portfolio">
        <Container fluid={true}>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="header-title text-center">
                <p className="text-uppercase text-muted mb-2">Portfolio</p>
                <h3>Our Works</h3>
                <div className="title-border mt-3"></div>
                <p className="title-desc text-muted mt-3">
                  We create great Digital experiences within the Marketing Technology Stack (more to come)
                </p>
              </div>
            </Col>
          </Row>
          <Row className="my-4 pt-2">
            <Col lg={12}>
              <div className="filters-group">
                <ul className="nav filter-options list-unstyled list-inline justify-content-center">
                  <li
                    className={
                      displayCategory === "All"
                        ? "nav-link list-inline-item mt-2 active"
                        : "nav-link list-inline-item mt-2"
                    }
                    onClick={() => setCategory("All")}
                  >
                    All
                  </li>

                  <li
                    className={
                      displayCategory === "Social Media"
                        ? "nav-link list-inline-item mt-2 active"
                        : "nav-link list-inline-item mt-2"
                    }
                    onClick={() => setCategory("Social Media")}
                  >
                    Social Media
                  </li>

                </ul>
              </div>
            </Col>
          </Row>

          <Container className="mt-5" fluid={true}>
            <Row className="g-2" id="grid">
              {GalleryData.filter(
                ({ category }) =>
                  category.includes(displayCategory) ||
                  displayCategory === "All"
              ).map(({ title, image, category, link }, key) => (
                <Col
                  key={key}
                  className="picture-item"
                  data-groups={category}
                  md={6}
                  lg={3}
                >
                  <div className="portfolio-box rounded">
                    <img className="img-fluid" src={image} alt="work-img" />
                    <div className="portfolio-content">
                      <div className="img-view">
                        <i
                          onClick={() => {
                            setphotoIndex(key);
                            setisOpen(!isOpen);
                            console.log(key);
                          }}
                          className="mdi mdi-plus text-muted image-popup"
                        ></i>
                        <div className="gallery-overlay"></div>
                      </div>
                      <div className="portfolio-caption">
                        <Link to="/#" className="text-primary">
                          <h5 className="mb-1 fs-18">{title}</h5>
                        </Link>
                        <p className="mb-0">{category.join(", ")} (<a href={link} target="_blank" rel="noreferrer">see docs</a>)</p>
                      </div>
                    </div>
                  </div>
                  {/* <!--end portfolio-box--> */}
                </Col>
              ))}

              {/* lightbox portfolio images */}

              {isOpen && (
                <Lightbox
                  mainSrc={images[photoIndex]}
                  nextSrc={images[(photoIndex + 1) % images.length]}
                  prevSrc={
                    images[(photoIndex + images.length - 1) % images.length]
                  }
                  onCloseRequest={() => setisOpen(false)}
                  onMovePrevRequest={() =>
                    setphotoIndex(
                      (photoIndex + images.length - 1) % images.length
                    )
                  }
                  onMoveNextRequest={() =>
                    setphotoIndex((photoIndex + 1) % images.length)
                  }
                />
              )}
            </Row>
          </Container>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default Gallery;
